import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useChaptersList() {
  // Use toast
  const toast = useToast();

  const refChaptersListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name" },
    { key: "sections" },
    { key: "lessons" },
    { key: "exercises" },
    { key: "actions" },
  ];
  const per_page = ref(10);
  const totalChapters = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [10, 20, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  // Add chapter variables
  const addChapterModal = ref(false)
  const addChapterName = ref("")
  const addChapterDescription = ref("")
  const addTranslation = ref("no")
  const addChapterNameTranslation = ref("")
  const addChapterDescriptionTranslation = ref("")

  // Single Chapter Data
  const chapterData = ref(null);

  // Edit chapter variables
  const editChapterModal = ref(false);
  const editChapterId = ref("");
  const editChapterName = ref("");
  const editChapterDescription = ref("");
  const editTranslation = ref("no")
  const editChapterNameTranslation = ref("");
  const editChapterDescriptionTranslation = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refChaptersListTable.value
      ? refChaptersListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalChapters.value,
    };
  });

  const refetchData = () => {
    refChaptersListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchChapters = (ctx, callback) => {
    store
      .dispatch("app-content/fetchChapters", {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: chapters, total } = response.data.data;

        callback(chapters);
        totalChapters.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching chapters list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const storeChapter = (ctx, callback) => {
    const formData = new FormData();
    formData.append("name", addChapterName.value);
    formData.append("description", addChapterDescription.value);
    formData.append("translation[name]", addChapterNameTranslation.value)
    formData.append("translation[description]", addChapterDescriptionTranslation.value)

    store
      .dispatch("app-content/addChapter", formData)
      .then(() => {
        refetchData();
        addChapterModal.value = false;
        addChapterName.value = "";
        addChapterDescription.value = "";
        addTranslation.value = false;
        addChapterNameTranslation.value = ""
        addChapterDescriptionTranslation.value = ""
        toast({
          component: ToastificationContent,
          props: {
            title: "Chapter Added.",
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the chapter.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateChapter = () => {
    const formData = new FormData();
    formData.append("name", editChapterName.value);
    formData.append("description", editChapterDescription.value);
    formData.append("translation[name]", editChapterNameTranslation.value)
    formData.append("translation[description]", editChapterDescriptionTranslation.value)

    store
      .dispatch("app-content/updateChapter", {
        id: editChapterId.value,
        data: formData,
      })
      .then((response) => {
        chapterData.value = response.data.data;
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: "Chapter updated.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
        editChapterModal.value = false;
        editChapterId.value = "";
        editChapterName.value = "";
        editChapterDescription.value = "";
        editChapterNameTranslation.value = "";
        editChapterDescriptionTranslation.value = ""
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the chapter.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchChapters,
    storeChapter,
    updateChapter,
    tableColumns,
    per_page,
    current_page,
    totalChapters,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refChaptersListTable,
    refetchData,

    addChapterModal,
    addChapterName,
    addChapterDescription,
    addTranslation,
    addChapterNameTranslation,
    addChapterDescriptionTranslation,

    chapterData,

    editChapterModal,
    editChapterId,
    editChapterName,
    editChapterDescription,
    editTranslation,
    editChapterNameTranslation,
    editChapterDescriptionTranslation,
  };
}
