<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-1"
              variant="primary"
              @click="addChapterModal = true"
            >
              Add Chapter
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Add Chapter Modal -->
      <b-modal
        v-model="addChapterModal"
        hide-footer
        centered
        size="lg"
        title="Add Chapter"
      >
        <b-form-group
          label="Enter the Chapter Name"
          label-for="enterName"
        >
          <b-form-input
            id="enterName"
            v-model="addChapterName"
            placeholder="Enter the Chapter Name"
          />
        </b-form-group>

        <b-form-group
          label="Chapter Description"
          label-for="chapterDescription"
        >
          <b-form-textarea
            v-model="addChapterDescription"
            placeholder="Enter the chapter description"
            rows="3"
            :state="addChapterDescription.length <= maxChar"
            class="char-textarea"
            :class="addChapterDescription.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="addChapterDescription.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ addChapterDescription.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add Translation? </span>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="addTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="addTranslation === 'yes'">
          <b-form-group
            label="Enter the Chapter Name Translation"
            label-for="enterNameTranslation"
          >
            <b-form-input
              id="enterNameTranslation"
              v-model="addChapterNameTranslation"
              placeholder="Enter the Chapter Name Translation"
            />
          </b-form-group>

          <b-form-group
            label="Chapter Description Translation"
            label-for="chapterDescriptionTranslation"
          >
            <b-form-textarea
              v-model="addChapterDescriptionTranslation"
              placeholder="Enter the chapter description translation"
              rows="3"
              :state="addChapterDescriptionTranslation.length <= maxChar"
              class="char-textarea"
              :class="addChapterDescriptionTranslation.length >= maxChar ? 'text-danger' : ''"
            />
            <small
              class="textarea-counter-value float-right"
              :class="addChapterDescriptionTranslation.length >= maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ addChapterDescriptionTranslation.length }}</span> /
              {{ maxChar }}
            </small>
          </b-form-group>
        </div>

        <b-button
          variant="outline-primary"
          @click="storeChapter()"
        >
          Submit
        </b-button>
      </b-modal>

      <!-- Edit Chapter Modal -->
      <b-modal
        v-model="editChapterModal"
        hide-footer
        centered
        size="lg"
        title="Edit Chapter"
      >
        <b-form-group
          label="Enter the Chapter Name"
          label-for="enterName"
        >
          <b-form-input
            id="enterName"
            v-model="editChapterName"
            placeholder="Enter the Chapter Name"
          />
        </b-form-group>

        <b-form-group
          label="Chapter Description"
          label-for="chapterDescription"
        >
          <b-form-textarea
            v-model="editChapterDescription"
            placeholder="Enter the chapter description"
            rows="3"
            :state="editChapterDescription.length <= maxChar"
            class="char-textarea"
            :class="editChapterDescription.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="editChapterDescription.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ editChapterDescription.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <div class="demo-inline-spacing mb-1">
          <span>Add/Edit Translation? </span>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="yes"
            class="custom-control-primary"
          >
            Yes
          </b-form-radio>
          <b-form-radio
            v-model="editTranslation"
            name="some-radio9"
            value="no"
            class="custom-control-primary"
          >
            No
          </b-form-radio>
        </div>

        <div v-if="editTranslation === 'yes'">
          <b-form-group
            label="Enter/Edit the Chapter Name Translation"
            label-for="editNameTranslation"
          >
            <b-form-input
              id="editNameTranslation"
              v-model="editChapterNameTranslation"
              placeholder="Enter/Edit the Chapter Name Translation"
            />
          </b-form-group>

          <b-form-group
            label="Chapter Description Translation"
            label-for="editDescriptionTranslation"
          >
            <b-form-textarea
              v-model="editChapterDescriptionTranslation"
              placeholder="Enter the chapter description translation"
              rows="3"
              :state="editChapterDescriptionTranslation.length <= maxChar"
              class="char-textarea"
              :class="editChapterDescriptionTranslation.length >= maxChar ? 'text-danger' : ''"
            />
            <small
              class="textarea-counter-value float-right"
              :class="editChapterDescriptionTranslation.length >= maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ editChapterDescriptionTranslation.length }}</span> /
              {{ maxChar }}
            </small>
          </b-form-group>
        </div>

        <b-button
          variant="outline-primary"
          @click="updateChapter"
        >
          Submit
        </b-button>
      </b-modal>

      <b-table
        ref="refChaptersListTable"
        class="position-relative"
        :items="fetchChapters"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Sections -->
        <template #cell(sections)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sections_count }}</span>
          </div>
        </template>

        <!-- Column: Lessons -->
        <template #cell(lessons)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.lessons.length }}</span>
          </div>
        </template>

        <!-- Column: Exercises -->
        <template #cell(exercises)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ calculateExercisesNumber(data.item) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            class="mr-1"
            size="sm"
            variant="outline-info"
            :to="{ name: 'apps-sections-list', params: { chapter_id: data.item.id } }"
          >
            View Sections
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="editChapterDetails(data.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalChapters"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BModal,
  BFormTextarea,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useChaptersList from './useChaptersList'
import contentStore from '../contentStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    BModal,
    BFormTextarea,
    BFormRadio,

    vSelect,
  },
  setup() {
    const CONTENT_APP_STORE_MODULE_NAME = 'app-content'

    const maxChar = ref(200)

    // Register module
    if (!store.hasModule(CONTENT_APP_STORE_MODULE_NAME)) { store.registerModule(CONTENT_APP_STORE_MODULE_NAME, contentStore) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTENT_APP_STORE_MODULE_NAME)) { store.unregisterModule(CONTENT_APP_STORE_MODULE_NAME) }
    })

    const {
      fetchChapters,
      storeChapter,
      updateChapter,
      tableColumns,
      per_page,
      current_page,
      totalChapters,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChaptersListTable,
      refetchData,

      addChapterModal,
      addChapterName,
      addChapterDescription,
      addTranslation,
      addChapterNameTranslation,
      addChapterDescriptionTranslation,

      chapterData,

      editChapterModal,
      editChapterId,
      editChapterName,
      editChapterDescription,
      editTranslation,
      editChapterNameTranslation,
      editChapterDescriptionTranslation,
    } = useChaptersList()

    const calculateExercisesNumber = chapter => {
      let exercises = 0
      chapter.lessons.forEach(lesson => {
        exercises += lesson.exercises_count
      })
      // chapter.sections.forEach(section => {
      //   section.lessons.forEach(lesson => {
      //     exercises += lesson.exercises.length
      //   })
      // })

      return exercises
    }

    const editChapterDetails = item => {
      chapterData.value = item
      editChapterId.value = chapterData.value.id
      editChapterName.value = chapterData.value.name
      editChapterDescription.value = chapterData.value.description != null ? chapterData.value.description : ''
      editTranslation.value = 'no'
      editChapterNameTranslation.value = ''
      editChapterDescriptionTranslation.value = ''
      if (chapterData.value.translation != null) {
        editChapterNameTranslation.value = chapterData.value.translation.name
        editChapterDescriptionTranslation.value = chapterData.value.translation.description
        editTranslation.value = 'yes'
      }

      editChapterModal.value = true
    }

    const isAddNewChapterSidebarActive = ref(false)

    return {
      // Sidebar
      isAddNewChapterSidebarActive,

      fetchChapters,
      storeChapter,
      updateChapter,
      tableColumns,
      per_page,
      current_page,
      totalChapters,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChaptersListTable,
      refetchData,

      maxChar,
      calculateExercisesNumber,

      // Add Chapter
      addChapterModal,
      addChapterName,
      addChapterDescription,
      addTranslation,
      addChapterNameTranslation,
      addChapterDescriptionTranslation,

      chapterData,
      editChapterDetails,

      // Edit Chapter
      editChapterModal,
      editChapterId,
      editChapterName,
      editChapterDescription,
      editTranslation,
      editChapterNameTranslation,
      editChapterDescriptionTranslation,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
